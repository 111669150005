//  Dutch
export default{
    form: {
        startSurveyButton: "Laten we beginnen",
        nextButton: "Volgende",
        submitAnswerButton: "Antwoord Verzenden",
        continueButton: "Doorgaan",
        finishButton: "Voltooien",
        headerPercentageCompletion: 'voltooid',
        headerQuestionsCompleted: 'vragen',
        headerDefaultErrorMessage: 'Oeps! Er ging iets mis.',
        brandingPoweredBy: 'Mogelijk gemaakt door',
        brandingPromoWhySurvey: 'Waarom een enquête als je <b>{product}</b> kunt gebruiken?',
        brandingPromoGetStarted: 'Begin vandaag nog!',
        submissionPending: "Uw voiceform wordt verzonden. Sluit het browservenster niet.",
        submissionCanCloseWindow: "Uw voiceform is succesvol verzonden. U kunt het venster nu sluiten 👍",
        mediaAttachmentOverlayCloseButton: "Sluiten",
        expiredFormHeader: "Bedankt voor uw bezoek!",
        expiredFormMessage: "Het lijkt erop dat deze enquête is verlopen. Neem contact op met de maker van de voiceform om dit te melden.",
        notPublishedFormHeader: "De voiceform is niet gepubliceerd!",
        notPublishedFormMessage: "Het lijkt erop dat deze enquête niet is gepubliceerd. Neem contact op met de maker van de voiceform om dit te melden.",
        notPublishedFormRetryButton: "Opnieuw proberen",
        errorFormHeader: "Oeps!",
        errorFormMessage: "Er ging iets mis. Probeer het later opnieuw.",
        errorFormRetryButton: "Opnieuw proberen",
        emptyFormHeader: "Bedankt voor uw bezoek!",
        emptyFormMessage: "Oeps! Deze form is momenteel leeg, zonder vragen of invulvelden. Onze excuses voor het ongemak. Neem contact op met de maker van deze form om dit probleem te melden. Bedankt voor uw begrip en medewerking!",

        restoreFormHeader: "Het lijkt erop dat u een form aan het invullen bent!",
        restoreFormMessage: "Wilt u doorgaan waar u gebleven was?",
        restoreFormButton: "Doorgaan met Formulier",
        restoreFormButtonNew: "Opnieuw Beginnen",

        audioPermissionHeader: "Wilt u met uw stem antwoorden?",
        audioPermissionMessage: "Praten maakt het beantwoorden snel en gemakkelijk.",
        audioPermissionAcceptButton: "Ja, graag",
        audioPermissionDenyButton: "Nee, bedankt",
        audioPermissionErrorHeader: "Microfoon is niet beschikbaar",
        audioPermissionErrorMessage: "Controleer de machtigingen van uw browser om toegang tot de microfoon in te schakelen.",
        audioPermissionErrorButton: "Oké",
        audioRecorderFailedUpload: "Uploaden van de opname mislukt",
        silenceDetectorError: "We kunnen u niet horen. Controleer uw microfooninstellingen. Tip: het kan uw externe microfoon zijn.",
        audioRecorderButtonRecord: "Opnemen",
        audioRecorderButtonRecordMore: "Meer Opnemen",
        voiceResponseSelectionMessage: "Kies hoe u wilt antwoorden...",
        voiceResponseSelectionOrDivider: "of",
        voiceResponseTextInputPlaceholder: "Typ antwoord",
        voiceResponseTextInputCharacterCounterMinMessage: "Voer minimaal {min} tekens in",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} tekens (minimaal {min} tekens)",
        voiceResponseTextInputCharacterValidationMessage: "{count} tekens (minimaal {min} tekens)",

        // checkbox
        checkboxValidationTooFew: "U moet minimaal {min} optie(s) selecteren",
        checkboxValidationTooMany: "U mag maximaal {max} optie(s) selecteren",
        checkboxNoneOfTheAboveOption: "Geen van bovenstaande",
        checkboxInvalidAnswer: "Ongeldig antwoord.",

        // datepicker
        datePickerPlaceholder: "Selecteer datum",

        // dropdown
        // email
        emailLabel: "E-mail",
        emailInvalid: "Ongeldig e-mailadres.",
        // file-upload
        fileUploadPluginNameCamera: "Camera",
        fileUploadPluginNameCameraVideo: "Video opnemen",
        fileUploadPluginScreenCast: "Schermopname",
        fileUploadVideoImportFilesDefault: 'Neem op of upload een video via:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Selecteer een optie voor video-opname:',
        fileUploadVideoImportFilesNoCamera: 'Klik op een knop om een schermvideo op te nemen',
        fileUploadVideoImportFilesNoScreenCast: 'Klik op een knop om een video op te nemen',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Geen opties beschikbaar om een video op te nemen',

        // matrix
        matrixValidationMessage: "Het antwoord is geldig.",
        matrixRow: "Rij",
        matrixColumn: "Kolom",
        matrixRowRequired: "De rij {rowTitle} is verplicht.",
        matrixRadioGroup: "Radiogroep",
        matrixCheckboxGroup: "Selectievakgroep",
        matrixGroupRequired: "Voor de rij {rowTitle}. {type} {groupTitle} is verplicht.",
        matrixColumnRequired: "Voor de rij {rowTitle}. De kolom {columnTitle} is verplicht.",
        matrixColumnInvalid: "Voor de rij {rowTitle}. De kolom {columnTitle} is ongeldig.",
        matrixRequired: "Verplicht.",
        matrixNumericMustBeNumber: "Moet een nummer zijn.",
        matrixNumericMustBeGreaterThenMin: "Moet groter zijn dan {min}.",
        matrixNumericMustBeLessThenMax: "Moet kleiner zijn dan {max}.",
        matrixNumericMustBeInteger: "Moet een geheel getal zijn.",
        matrixNumericInvalidNumber: "Ongeldig nummer.",

        // name
        nameLabel: "Naam",
        nameInvalid: "Geef voor- en achternaam op.",

        // nps
        npsNotLikely: "Helemaal niet waarschijnlijk",
        npsExtremelyLikely: "Zeer waarschijnlijk",

        // numeric input
        numericInputRequired: "Verplicht.",
        numericInputMustBeNumber: "Moet een nummer zijn.",
        numericInputMustBeGreaterThenMin: "Moet groter zijn dan {min}.",
        numericInputMustBeLessThenMax: "Moet kleiner zijn dan {max}.",
        numericInputMustBeInteger: "Moet een geheel getal zijn.",
        numericInputInvalidNumber: "Ongeldig nummer.",
        numericInputPlaceholder: "Voer nummer in",

        // phone
        phoneInvalid: "Het telefoonnummer is ongeldig.",
        phoneCountrySelectorLabel: 'Landcode',
        phoneCountrySelectorError: 'Kies land',
        phoneNumberLabel: 'Telefoonnummer',
        phoneExample: 'Voorbeeld:',

        // boolean
        booleanYesLabel: "Ja",
        booleanNoLabel: "Nee",

        //questionStep
        questionStepAudioQuestionLabel: "Audio Vraag",

        // errors
        invalidPhoneNumber: "{phone} is een ongeldig telefoonnummer.",
        invalidEmail: "{email} is een ongeldig e-mailadres.",
        questionIsRequired: "De vraag is verplicht.",
        answerIsNotValid: "Het antwoord is niet geldig.",
        unfinishedProbingDialogError: "Maak het dialoog af.",
        cannotResumePartialResponse: "Kan gedeeltelijke reactie niet hervatten.",
        failedToSubmitForm: "Verzenden van formulier mislukt. Controleer uw internetverbinding en probeer het opnieuw.",

        //language picker
        searchLanguage: "Zoek taal",
    }
}
